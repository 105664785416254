import {INPUT_IDS, onSearchLocations, TypeSearch} from "@/modules/meilisearch/meilisearch.core";
import appConfig from "@/config";
import {getInputValue} from "@/utility/helper";

export type TYPE_LOCATION = {
  id: string,
  url: string,
  type: string,
  search_label: string,
  full_slug: string,
}

/**
 * Event click on place
 * */
export const onTypeSelected = (
  idElement: string = INPUT_IDS.SEARCH_BOX,
  type: TypeSearch = '',
  redirectProject = true,
  redirectLocation = false,
) => {
  // set focus to search box
  let searchBox = document.getElementById(idElement) as HTMLInputElement
  if (searchBox) {
    searchBox.focus();
  }
  // filter location
  onSearchLocations(idElement, type, redirectProject, redirectLocation).then(r => {
    setTimeout(() => {
      const meiliHits = document.getElementById(`meili-hits-${idElement}`) as HTMLDivElement
      let placeDropdown = document.getElementById(`meili-default-location-hits-${idElement}`) as HTMLDivElement
      // after get result close place dropdown and show meili hits
      if (placeDropdown) {
        placeDropdown.classList.add('hidden');
      }
      if (meiliHits) {
        meiliHits.classList.remove('hidden');
      }
    }, 500)
  });
}

/**
 * Event click on location
 * */
export const onLocationSelected = (
  locationObj: TYPE_LOCATION,
  idElement: string = INPUT_IDS.SEARCH_BOX,
  redirectProject = true,
  redirectLocation = false,
) => {
  // set value search input and location input
  let location = document.getElementById(idElement+'_'+INPUT_IDS.FILTER_LOCATION) as HTMLInputElement
  let locationType = document.getElementById(idElement+'_'+INPUT_IDS.FILTER_LOCATION_TYPE) as HTMLInputElement
  // set type value
  if (locationType) {
    locationType.value = locationObj.type;
  }

  // redirect to project/new-development page
  if (locationObj.type === "project") {
    if (redirectProject) {
      window.location.href = locationObj.url;
      return;
    }
    // set value location for type project
    location.value = locationObj.id;
  } else {
    if (redirectLocation) {
      const tenure = getInputValue(`filter-tenure-${idElement}`) || 'sale';
      updateSearchBox(idElement, locationObj.search_label);
      window.location.href = `${appConfig.domain}/for-${tenure}/${locationObj.full_slug}`;
      return;
    }
    // set value location
    location.value = locationObj.full_slug;
  }
  updateSearchBox(idElement, locationObj.search_label);
}

const updateSearchBox = (idElement: string, label: string) => {
  let searchBox = document.getElementById(idElement) as HTMLInputElement
  if (searchBox) {
    searchBox.value = label;
    document.getElementById(`meili-hits-${idElement}`)?.classList.add('hidden');
  }
}

export const typeFiltersLabel = {
  en: {
    house: 'Detached House',
    condo: 'Condo',
    apartment: 'Apartment',
    townhouse: 'Townhouse',
    villa: 'Villa',
    land: 'Land',
    office: 'Office',
    shop: 'Shop',
    warehouse: 'Warehouse',
    hotel: 'Hotel',
    building: 'Building',
  },
  th: {
    house: 'บ้านเดี่ยว',
    apartment: 'อพาร์ทเม้นท์',
    condo: 'คอนโด',
    townhouse: 'ทาวน์เฮ้าส์',
    villa: 'วิลล่า',
    land: 'ที่ดิน',
    office: 'ออฟฟิศ',
    shop: 'ร้านค้า',
    warehouse: 'โกดัง',
    hotel: 'โรงแรม',
    building: 'อาคารพาณิชย์',
  },
  ae: {
    house: 'منزل',
    condo: 'شقة',
    apartment: 'شقة',
    townhouse: 'تاون هاوس',
    villa: 'فيلا',
    land: 'أرض',
    office: 'مكتب',
    shop: 'محل',
    warehouse: 'مستودع',
    hotel: 'الفندق',
    building: 'مبنى',
  },
}

export const bedBathFiltersLabel = {
  en: {
    beds: 'beds',
    baths: 'baths',
  },
  th: {
    beds: 'ห้องนอน',
    baths: 'ห้องน้ำ',
  },
  ae: {
    beds: 'غرفة نوم',
    baths: 'حمام',
  }
}